import {ReactNode, useEffect, useState} from "react";
import UserTypeOptional  from "../../types/user.types";
import {createProfile, listProfiles, logout, selectProfile, whoami} from "../../utils/communicator";
import LogoTypeFace from "../../assets/imgs/switchflip-typeface.png";
import StorefrontIcon from '@mui/icons-material/Storefront';
import PencilWithLineIcon from '@mui/icons-material/BorderColor';
import LinkIcon from '@mui/icons-material/Link';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WalletIcon from '@mui/icons-material/Wallet';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import HelpIcon from '@mui/icons-material/HelpCenter';

import './header.styles.scss';

import {AppBar, Box, Toolbar, Link, Avatar} from '@mui/material';

import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from "../mui/loading-button";
import Profile from "../../types/profile.types";
import {whoamiResponseType} from "../../types";
import defaultProfileImage from '../../assets/imgs/dj3n-logo-circlable.png'
import routes from "../../routes";
import {CancelButton} from "../button/button.component";

const settings = [
  {
    label: 'Profiles',
    icon: StorefrontIcon,
    action: 'open-switch-profile',
  },
  {
    label: 'Edit Profile',
    icon: PencilWithLineIcon,
    href: routes.profile.edit,
  },
  {
    label: 'Edit Links',
    icon: LinkIcon,
    href: routes.profile.editLinks,
  },
  {
    label: 'Account',
    icon: WalletIcon,
    href: routes.account,
  },
  {
    label: 'Logout',
    icon: CancelIcon,
    href: routes.logout,
    after: <hr/>
  },
  {
    label: 'Help',
    icon: HelpIcon,
    href: routes.external.docs,
    target: '_blank',
  }
];

enum TriState {
  unknown = 0,
  true = 1,
  false = 2,
}

function MenuContent({label, icon, href, className}: {label: string, icon?: ReactNode, href?: string, className?: string}) {
  return (
    <div className={`menu-content ${className}`}><a className='no-link' href={href}>{icon}<span className='label'>{label}</span></a></div>
  )
}

export function Header({disableMenu, children}: {disableMenu?: boolean, children?: ReactNode}) {
  const [isAuthenticated, setIsAuthenticated] = useState<TriState>(TriState.unknown);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorElSwitchProfile, setAnchorElSwitchProfile] = React.useState<null | HTMLElement>(null);
  const [session, setSession] = useState<whoamiResponseType>();
  const [profiles, setProfiles] = useState<Profile[]>([]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  function handleOpenSwitchProfileMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorElSwitchProfile(event.currentTarget);
  }

  async function createNewProfile() {
    await createProfile()
    window.location.href = routes.profile.edit
  }

  const handleUserMenuClick = (event: any) => {
    const elm = event.currentTarget;
    console.log('target', elm);
    if (!elm) return;

    if (elm.getAttribute('data-action') === 'open-switch-profile') {
      return handleOpenSwitchProfileMenu(event)
    }

    const href = elm.getAttribute('data-href');
    if (href) {
      const target = elm.getAttribute('data-target');
      if (target === '_blank') return window.open(href)
      return window.location.href = href;
    }

    setAnchorElUser(null);
  };

  function handleProfileSwitchMenuClick(event: any) {
    setAnchorElUser(null);
    setAnchorElSwitchProfile(null);
    const elm = event.currentTarget;
    if (!elm) return;

    const action = elm.getAttribute("data-action");
    if (action === 'profile') {
      const targetProfile = elm.getAttribute('data-uuid');
      selectProfile(targetProfile).then((b) => {
        return window.location.href = routes.profile.view
      }).catch((e) => {
        console.error(e);
      })
    } else if (action === 'create-profile') {
      createNewProfile().catch(e => console.error('Failed to create profile', e))
    }
  }

  useEffect(() => {
    whoami().then((u: whoamiResponseType) => {
      setIsAuthenticated((!!u.user.uuid) ? TriState.true : TriState.false);
      setSession(u);
    }).catch((e) => {
      if (e?.response?.status === 403) {
        setIsAuthenticated(TriState.false);
        return;
      }
      console.error(e)
    });
  }, [setIsAuthenticated, setSession]);

  useEffect(() => {
    if (!isAuthenticated) return
    listProfiles().then((ps) => {
      const allProfiles = [...ps.owner, ...ps.member]
      setProfiles(allProfiles)
    })
  }, [isAuthenticated, setProfiles])

  return (
    <AppBar className='dj3n-header' position="static" sx={{'padding-right': 0}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link href='/' aria-details='Go home'><img className='dj3n-logo' src={LogoTypeFace} alt='DJ3N' /></Link>

          {children && children}
          { !disableMenu && <>
            <CancelButton className={`dj3n-appbar-login ${isAuthenticated === TriState.false ? '' : 'hide'}`} label='Start' href={routes.login} />
            <Box className={`dj3n-appbar-action ${isAuthenticated === TriState.true ? '' : 'hide'}`} sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar className='dj3n-appbar-action-avatar' alt='Menu' src={session?.profile?.profileImage || defaultProfileImage} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '30px' }}
              id="menu-appbar"
              className='dj3n-menu'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              disableScrollLock
              open={Boolean(anchorElUser)}
              onClose={handleUserMenuClick}
            >
              {settings.map((s) => (
                <>
                  <MenuItem key={s.label} onClick={handleUserMenuClick} data-action={s.action} data-href={s.href} data-target={s.target} >
                    <MenuContent label={s.label} icon={<s.icon />} href={s.target !== '_blank' ? s.href : undefined} />
                  </MenuItem>
                  {s.after && s.after}
                </>
              ))}
            </Menu>
            <Menu
              sx={{ mt: '30px' }}
              id="menu-profile-switch"
              className='dj3n-menu second'
              anchorEl={anchorElSwitchProfile}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              disableScrollLock
              open={Boolean(anchorElSwitchProfile)}
              onClose={handleProfileSwitchMenuClick}
            >
              {profiles.map((p, idx) => (
                <MenuItem key={p.uuid} data-action="profile" data-uuid={p.uuid} onClick={handleProfileSwitchMenuClick}>
                  <MenuContent className={p.uuid === session?.profile.uuid ? 'active' : ''} label={p.name || `Profile ${idx + 1}`} icon={
                    p.profileImage && <Avatar className='avatar' alt='' src={p.profileImage || undefined}>{p.name?.slice(0, 1)}</Avatar> ||
                    <AccountCircleIcon/>
                  } />
                </MenuItem>
              ))}
              <MenuItem key={'create-profile'} data-action='create-profile' onClick={handleProfileSwitchMenuClick}>
                <MenuContent label={'Create Profile'} icon={<AddCircleIcon />} />
              </MenuItem>
            </Menu>
          </Box>
          </>}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
