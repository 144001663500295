import React, {ChangeEvent, useEffect, useState} from "react";
import Frame from "../../components/frame";

import "./login.styles.scss";
import {useSearchParams} from "react-router-dom";
import routes from "../index";
import TextField from "../../components/mui/text-field";
import LoadingButton from "../../components/mui/loading-button";
import {login} from "../../utils/communicator";

export function Asterisk() {
  return (
    <span className='asterisk-required'>*</span>
  )
}

enum Stage {
  EMAIL,
  AUTH_CODE
}

const Login = () => {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [email, setEmail] = useState('');
  const [isAuthCodeValid, setIsAuthCodeValid] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [stage, setStage] = useState<Stage>(Stage.EMAIL)
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const url = searchParams.get('url') || undefined;
  const page = searchParams.get('page') || undefined;

  useEffect(() => {
    setIsEmailValid(
      email.includes('@') &&
      email.includes('.') &&
      email.length >= 6 &&
      email.lastIndexOf('.') !== email.length - 1
    );
  }, [email, setIsEmailValid]);

  useEffect(() => {
    setIsAuthCodeValid(authCode.length === 6)
  }, [authCode, setIsAuthCodeValid])

  function emailOnChange(evt: any) {
    const v = evt.target.value;
    setEmail(v);
  }

  function onAuthCodeChange(evt: any) {
    const v = evt.target.value;
    const filtered = v.replace(/\D/g, '');
    setAuthCode(filtered);
  }

  function handleAction() {
    if (stage === Stage.EMAIL) {
      sendCode()
        .catch((e) => console.error('Failed to send email code', e))
        .finally(() => setIsLoading(false))
    } else if (stage === Stage.AUTH_CODE) {
      verifyCode()
        .catch((e) => console.error('Code verification failed', e))
        .finally(() => setIsLoading(false))
    }
  }

  async function sendCode() {
    setIsLoading(true)
    await login.email.init({email})
    setStage(Stage.AUTH_CODE)
  }

  async function verifyCode() {
    setIsLoading(true)
    await login.email.verify({email, code: authCode})
    window.location.href = routes.home
  }

  return (
    <Frame>
      <div className="login">
        {stage === Stage.EMAIL && <TextField label={'Enter your email'} placeholder={'someone@somewhere.com'} onChange={emailOnChange} value={email} />}
        {stage === Stage.AUTH_CODE && <h3>Check your email for a verification code ({email})</h3>}
        {stage === Stage.AUTH_CODE && <TextField label={'Enter the code you received via email'} placeholder={'123543'} onChange={onAuthCodeChange} value={authCode} />}

        <LoadingButton className='primary-action' onClick={handleAction}
                       disabled={(stage === Stage.EMAIL && !isEmailValid) || (stage === Stage.AUTH_CODE && !isAuthCodeValid)}
                       loading={isLoading}
        >{stage === Stage.EMAIL ? 'Login or Register' : 'Verify'}</LoadingButton>
      </div>
    </Frame>
  );
};

export default Login;
