import {Box, InputAdornment} from "@mui/material";
import TextField, {TextFieldHandle} from '../../components/mui/text-field';
import LoadingButton from '../../components/mui/loading-button';
import {ProfileBanner} from "../../components/user-profile/user-profile.component";

import './profile-editor.component.scss';
import {useEffect, useState} from "react";
import UserTypeOptional from "../../types/user.types";
import {updateProfile, updateUser, whoami} from "../../utils/communicator";
import {uploadFiles} from "../../utils/file";
import Frame from '../../components/frame';
import {whoamiResponseType} from "../../types";
import ProfileTypes, {BattleOfTheBandsEventType, Event} from "../../types/profile.types";

import defaultUpload from '../../assets/imgs/upload-black.png'
import Button, {CancelButton} from "../../components/button/button.component";
import routes from "../index";
import {SingleAudioDropzone} from "../../components/dropzone/dropzone.component";

function EventSection({session}: {session?: whoamiResponseType}) {
  if (!session) return <></>
  if (session.profile.eventRegistrations && session.profile.eventRegistrations[Event.BATTLE_OF_THE_BANDS_2024] && session.profile.eventRegistrations[Event.BATTLE_OF_THE_BANDS_2023]?.type) {
    return (
      <>
        <h2>Battle of the Bands compeition</h2>
        <p>You're registered! Be sure to add a profile pic and upload a song.</p>
        <CancelButton label='Manage Registration' href={routes.botb.signupWithExisting} />
      </>
    )
  }
  return (
    <>
      <h2>Enter the Battle of the Bands compeition</h2>
      <p>If you're an artist that wishes to register this profile for the <a href='https://battleofthebands.com' target='_blank'>Battle of the Bands contest</a>, use the button below.</p>
      <CancelButton label='Enter Contest' href={routes.botb.signupWithExisting} />
    </>
  )
}

export default function ProfileEditor() {
  const [session, setSession] = useState<whoamiResponseType>();
  const [name, setName] = useState('');
  const [handle, setHandle] = useState('');
  const [bio, setBio] = useState('');
  const [portraitImage, setPortraitImage] = useState<File | null>(null);
  const [bannerImage, setBannerImage] = useState<File | null>(null);
  const [portraitImageUrl, setPortraitImageUrl] = useState('');
  const [bannerImageUrl, setBannerImageUrl] = useState('');
  const [uploadProgressPortrait, setUplaodProgressPortrait] = useState(0);
  const [uploadProgressBanner, setUplaodProgressBanner] = useState(0);
  const [audioFile, setAudioFile] = useState<File | null>(null)
  const [isPending, setIsPending] = useState(false);

  function onChangeName(evt: React.ChangeEvent<HTMLInputElement> ) {
    setName(evt.target.value);
  }

  function onChangeHandle(evt: React.ChangeEvent<HTMLInputElement>) {
    const v = evt.target.value.replace(/\W/g, '');
    setHandle(v);
  }

  function onChangeBio(evt: React.ChangeEvent<HTMLInputElement>) {
    const v = evt.target.value.slice(0, 169);
    setBio(v);
  }

  function _onImageChange(evt: React.ChangeEvent<HTMLInputElement>, setImage: ((f: File) => void), setImageUrl: ((s: string) => void)) {
    const target = evt.target as HTMLInputElement;
    const files = target.files as FileList;
    setImage(files[0])
    setImageUrl(URL.createObjectURL(files[0]));
  }
  function onChangePortrait(evt: React.ChangeEvent<HTMLInputElement>) {
    _onImageChange(evt, setPortraitImage, setPortraitImageUrl);
  }
  function onChangeBanner(evt: React.ChangeEvent<HTMLInputElement>) {
    _onImageChange(evt, setBannerImage, setBannerImageUrl);
  }

  async function uploadFile(image: File, setUploadProgress?: ((pct: number) => void)) {
    const aux = await uploadFiles(
      [image],
      setUploadProgress,
    );
    const key = aux[0].request.fields.key;
    if (setUploadProgress) {
      setUploadProgress(0);
    }
    return key;
  }

  useEffect(() => {
    async function getCurrentUser() {
      const u = await whoami();
      setSession(u);
      setName(u?.profile?.name!);
      setHandle(u?.profile?.handle!);
      setBio(u?.profile?.description!);
    }

    getCurrentUser().catch((e) => {
      console.error('Failed to get current user.', e);
    });
  }, [setSession, setName, setHandle, setBio]);

  function doneEditing() {
    window.location.href = `${routes.profile.view}`;
  }

  function submit() {
    async function doSubmit() {
      setIsPending(true);
      const uploadPromises: Array<null | Promise<string>> = [null, null, null];
      if (portraitImage) {
        uploadPromises[0] = uploadFile(portraitImage, setUplaodProgressPortrait);
      }
      if (bannerImage) {
        uploadPromises[1] = uploadFile(bannerImage, setUplaodProgressBanner);
      }
      if (audioFile) {
        uploadPromises[2] = uploadFile(audioFile)
      }
      const [portraitKey, bannerKey, audioKey] = await Promise.all(uploadPromises);

      const updates: ProfileTypes = {
        name,
        handle,
        description: bio,
      };
      if (portraitKey) {
        updates.profileImage = portraitKey;
      }
      if (bannerKey) {
        updates.profileImageBg = bannerKey;
      }
      if (audioKey) {
        updates.featuredAudio = {
          key: audioKey,
          name: audioFile?.name || '',
          mime: 'audio/*',
        }
      }

      await updateProfile(updates);
    }
    doSubmit()
      .then(doneEditing)
      .catch(e => console.error(e))
      .finally(() => {
        setIsPending(false);
        setUplaodProgressPortrait(0);
        setUplaodProgressBanner(0);
      });
  }

  return (
    <Frame>
    <div>
      <Box className='dj3n-profile-editor'>
        <Box style={{width: '100%'}}>
          <ProfileBanner
            {...session?.profile}
            profileImage={portraitImageUrl || session?.profile?.profileImage || defaultUpload}
            profileImageBg={bannerImageUrl || session?.profile?.profileImageBg || defaultUpload}
            backgroundSize={bannerImageUrl || session?.profile?.profileImageBg ? 'cover' : 'contain'}
            editable={!isPending}
            onChangePortrait={onChangePortrait}
            onChangeBanner={onChangeBanner}
          />
        </Box>
        <TextField id='name' label='Name' value={name} onChange={onChangeName} disabled={isPending} /><br />
        <TextFieldHandle id='username'
                   label='Username'
                   value={handle}
                   onChange={onChangeHandle}
                   disabled={isPending}
                   whitelist={[session?.profile?.handle].filter(Boolean) as string[]}
        /><br />
        <TextField id='bio'
                   label='Bio' rows={4} multiline value={bio} onChange={onChangeBio} disabled={isPending} /><br />
        <SingleAudioDropzone file={audioFile} setFile={setAudioFile} />
        <Button className='primary-action' onClick={submit} disabled={isPending} label='Save' />
        <hr />
        <div className='events'>
          <EventSection session={session} />
        </div>
      </Box>
    </div>
    </Frame>
  );
}