export enum Event {
  ETH_DENVER_2023 = '6d537dcb-78ef-456f-96db-2f3add6eae24',
  BATTLE_OF_THE_BANDS_2023 = '80b69808-635f-43e2-9b5b-e334e0f7c19f',
  BATTLE_OF_THE_BANDS_2024 = '80b69808-635f-43e2-9b5b-e334e0f72024',
}

export type EventRegistrations = {
  [Event.BATTLE_OF_THE_BANDS_2023]?: BattleOfTheBandsEvent
  [Event.BATTLE_OF_THE_BANDS_2024]?: BattleOfTheBandsEvent
}

type StampInfo = {
  registered?: Date
  active?: boolean
}

export enum BattleOfTheBandsEventType {
  FAN = 'fan',
  CREATOR = 'creator',
}

export type BattleOfTheBandsEvent = {
  type: BattleOfTheBandsEventType,
  genre?: string,
  subgenre?: string,
} & StampInfo

export type FeaturedMedia = {
  name: string
  key: string
  mime?: string
}

export type Profile = {
  uuid?: string
  owner?: string
  members?: string[] | string
  isStripeConnected?: boolean
  isFeatured?: boolean
  name?: string
  handle?: string
  profileImage?: string
  profileImageBg?: string
  description?: string
  featuredAudio?: FeaturedMedia
  events?: string[]
  eventRegistrations?: EventRegistrations
}

export default Profile
