import TextField, {TextFieldHandle} from '../../components/mui/text-field';
import Button from '../../components/button/button.component';
import Div100vh from 'react-div-100vh';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {ReactJSXElement} from '@emotion/react/types/jsx-namespace';
import {
  createProfile,
  getProfileByUuid,
  getUserByUuid,
  updateProfile,
  updateUser,
  whoami
} from '../../utils/communicator';
import CircularProgress from '@mui/material/CircularProgress';
import {smsLoginHandler} from '../../utils/sms-wallet/sms-wallet.utils';
import PhoneInput, {Value as E164Number, isValidPhoneNumber} from 'react-phone-number-input';
import {Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import {Asterisk} from '../auth/login.component';
import Select from '../../components/mui/select';
import {SingleValue} from 'react-select';
import allGenres from '../../utils/genres';
import _ from 'lodash';
import ProfileTypes, {BattleOfTheBandsEventType, Event} from '../../types/profile.types'

import './botb.style.scss'
import {whoamiResponseType} from "../../types";
import {useParams} from "react-router-dom";

enum FlowState {
  login,
  personal,
  soloOrGroup,
  bandName,
  genre,
  handle,
  submit,
  members,
  others
}

function _toKey(s: string) {
  return s.toLowerCase().replace(/\s/g, '_').replace(/\W/g, '')
}
function _toOptions(labels: string[], keys: string[]) {
  return labels.map((label, i) => {
    return {label, value: keys[i]}
  }).sort((a, b) => a.label.localeCompare(b.label))
}
function _genGenreOptions() {
  const primaryGenreLabels = Object.keys(allGenres)
  const primaryGenreKeys = primaryGenreLabels.map(_toKey)
  return _toOptions(primaryGenreLabels, primaryGenreKeys)
}
function getSecondaryGenreOptions(primary: string) {
  // @ts-ignore
  const secondary = allGenres[primary]
  console.log('looking up sub genres of ' + primary)
  if (!secondary) return []
  const secondaryLabels = Object.values(secondary) as string[]
  const secondaryKeys = secondaryLabels.map(_toKey)
  return _toOptions(secondaryLabels, secondaryKeys)
}

const genreOptions = _genGenreOptions()

function Login({onNext, setSession}: {onNext: () => void, setSession: (s: whoamiResponseType) => any}) {
  const [phone, setPhone] = useState<E164Number>('')
  const [loginRequired, setLoginRequired] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [tosCheck, setTosCheck] = useState(false);
  const [smsCheck, setSmsCheck] = useState(false);
  const [competitionCheck, setCompetitionCheck] = useState(false);

  useEffect( () => {
    whoami()
      .then((s) => {
        setSession(s)
        return onNext();
      }).catch(() => {
        setLoginRequired(true)
      })
  }, [setLoginRequired, onNext])

  useEffect(() => {
    if (!tosCheck || !smsCheck || !competitionCheck)
      return setIsValid(false)
    return setIsValid(isValidPhoneNumber(phone))
  }, [phone, tosCheck, smsCheck, competitionCheck])

  function phoneChange(v: E164Number | undefined) {
    setPhone(v || '')
  }

  function doLogin() {
    async function loginHandler() {
      try {
        await smsLoginHandler(phone, 'BOTB', '/botb');
      } catch (e) {
        console.log("Cannot login:", e);
      }
    }
    loginHandler().catch((e) => {
      console.error('loginHandler', e)
    })
  }

  function tosOnChange(e: ChangeEvent<HTMLInputElement>, b: boolean) {
    setTosCheck(b)
  }

  function smsOnChange(e: ChangeEvent<HTMLInputElement>, b: boolean) {
    setSmsCheck(b)
  }

  function competitionChange(e: ChangeEvent<HTMLInputElement>, b: boolean) {
    setCompetitionCheck(b)
  }

  if (!loginRequired) {
    return (
      <>
        Please wait, checking your identity.
      </>
    )
  } else {
    return (
      <div className='login'>
        <h2 className='title'>Submit your band</h2>
        <PhoneInput className='phone-input' onChange={phoneChange} defaultCountry='US' />
        <div className='tos-agreement'>
          <span>I agree to the following terms:</span>
          <FormGroup>
            <FormControlLabel control={<Checkbox required onChange={tosOnChange} checked={tosCheck} />} label={<span><a href='https://home.dj3n.com/terms-of-service/' target='_blank'>Terms of Service</a> and <a href='https://home.dj3n.com/privacy-policy/' target='_blank'>Privacy Policy</a><Asterisk /></span>} />
            <FormControlLabel control={<Checkbox onChange={smsOnChange} checked={smsCheck} required />} label={<span><a href='https://home.dj3n.com/sms-opt-in/' target='_blank'>SMS Opt-In</a><Asterisk /></span>} />
            <FormControlLabel control={<Checkbox required onChange={competitionChange} checked={competitionCheck} />} label={<span><a href='#' target='_blank'>Competition terms</a><Asterisk /></span>} />
          </FormGroup>
        </div>
        <Button className='primary-action' disabled={!isValid} onClick={doLogin} label='Continue' />
      </div>
    )
  }
}

function Personal({onNext, name, email, onChange, showTerms}: {onNext: () => void, name: string, email: string, onChange: (k: string, s: string) => void, showTerms?: boolean}) {
  const [competitionCheck, setCompetitionCheck] = useState(false);
  const isValid = name && email && (!showTerms || competitionCheck)

  function changeName(e: { target: { value: string; }; }) {
    onChange('name', e.target.value)
  }
  function changeEmail(e: { target: { value: string; }; }) {
    onChange('email', e.target.value)
  }
  function competitionChange(e: ChangeEvent<HTMLInputElement>, b: boolean) {
    setCompetitionCheck(b)
  }

  return (
    <>
      <h2 className='title'>Enter your name and email</h2>
      <TextField label='First & Last Name' onChange={changeName} value={name} />
      <TextField label='Email' onChange={changeEmail} value={email} />
      {showTerms &&
          <div className='login'><div className='tos-agreement'>
              <span>I agree to the following terms:</span>
              <FormGroup>
                <FormControlLabel control={<Checkbox required onChange={competitionChange} checked={competitionCheck} />} label={<span><a href='#' target='_blank'>Competition terms</a><Asterisk /></span>} />
              </FormGroup>
          </div></div>
      }
      <Button className='primary-action' label='Next' disabled={!isValid} onClick={onNext} />
    </>
  )
}

function SoloOrGroup({onNext, setIsGroup}: {onNext: () => void, setIsGroup: (v: boolean) => void}) {
  function setSolo() {
    setIsGroup(false)
    onNext()
  }
  function setGroup() {
    setIsGroup(true)
    onNext()
  }

  return (
    <>
      <h2 className='title'>Are you a band or solo artist?</h2>
      <Button className='primary-action' label='Band' onClick={setGroup} />
      <Button className='primary-action' label='Solo Artist' onClick={setSolo} />
    </>
  )
}

function BandName({onNext, onChange, groupName, isGroup}: {onNext: () => void, onChange: (k: string, v: string) => void, groupName: string, isGroup: boolean}) {
  function changeGroupName(e: { target: { value: string; }; }) {
    onChange('groupName', e.target.value)
  }

  return (
    <>
      <h2 className='title'>What is your {isGroup ? 'band' : 'artist'} name?</h2>
      <TextField label='Name' onChange={changeGroupName} value={groupName} />
      <Button className='primary-action' label='Next' onClick={onNext} />
    </>
  )
}

function Genre({onNext, onChange, genre, secondaryGenre}: {onNext: () => void, onChange: (k: string, v: { label: string, value: string }) => void, genre?: { label: string, value: string }, secondaryGenre?: { label: string, value: string }}) {
  const [localGenre, setLocalGenre] = useState<{label: string, value: string} | undefined>(genre)
  const [localSecondary, setLocalSecondary] = useState<{label: string, value: string} | undefined>(secondaryGenre)
  const [secondaryOptions, setSecondaryOptions] = useState<{label: string, value: string}[]>([])

  useEffect(() => {
    if (!localGenre || !localGenre.label) return
    const opts = getSecondaryGenreOptions(localGenre.label)
    if (_.isEqual(opts, secondaryOptions)) return
    setSecondaryOptions(opts)
  }, [genre, localGenre, setSecondaryOptions])

  useEffect(() => {
    console.log('localSecondary', [localSecondary, secondaryGenre])
    if (!localSecondary || _.isEmpty(localSecondary)) return setLocalSecondary(secondaryGenre)
    const objMatch = localSecondary && secondaryOptions.find((x) => x.value === localSecondary.value)
    if (secondaryOptions.length && objMatch) {
      console.log('Setting w/ localSecondary')
      return setLocalSecondary(objMatch)
    }
    setLocalSecondary({...secondaryOptions[0]})
  }, [setLocalSecondary, secondaryOptions])

  function onChangePrimary(option: SingleValue<{value: string, label: string}> | null) {
    if (!option) return
    if (_.isEqual(option, localGenre)) {
      return
    }
    setLocalGenre(option)
    onChange('genre', option)
  }

  function onChangeSecondary(option: SingleValue<{value: string, label: string}> | null) {
    if (!option) return onChange('genreSecondary', {label: '', value: ''})
    onChange('genreSecondary', {label: option.label, value: option.value})
    setLocalSecondary(option)
  }

  return (
    <>
      <h2 className='title'>Select a genre</h2>
      <Select className='dj3n-select' isSearchable onChange={onChangePrimary} options={genreOptions} value={localGenre} />
      <Select className='dj3n-select' isSearchable onChange={onChangeSecondary} options={secondaryOptions} value={localSecondary} />
      <Button className='primary-action' label='Next' onClick={onNext} disabled={!localGenre} />
    </>
  )
}

function SelectHandle({onNext, onChange, isGroup, handle}: {onNext: () => void, onChange: (k: string, v: string) => void, isGroup: boolean, handle: string}) {
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function setHandle(v: string) {
    onChange('handle', v)
  }

  const extra = isGroup ? ' for your band' : ''

  return (
    <>
      <h2 className='title'>Pick a username{extra}.</h2>
      <TextFieldHandle label='3/dj3n' value={handle} setHandle={setHandle} setIsValid={setIsValid} setIsLoading={setIsLoading} />
      <Button label={"Finish"} className='primary-action' onClick={onNext} disabled={isLoading || !isValid} />
    </>
  )
}

function Submit({}) {
  return (
    <>
      <h2 className='title'>Submitting your registration...</h2>
      <div className='spinner'><CircularProgress /></div>
    </>
  )
}

function NavButtons({onBack, disabled}: {onBack: () => void, disabled?: boolean}) {
  return (
    <Div100vh className='dj3n-botb-nav'>
      <div className='actions'>
        <Button label='←' onClick={onBack} disabled={disabled} />
      </div>
      <div className='powered-by'>
        <a href='/'><img src='/powered-by-dj3n.png' alt='powered by DJ3N' /></a>
      </div>
    </Div100vh>
  )
}

export default function BattleOfTheBands({useExisting: existingProfile}: {useExisting?: boolean}) {
  const [session, setSession] = useState<whoamiResponseType>()

  const [flowState, setFlowState] = useState<FlowState>(FlowState.login)
  const [slide, setSlide] = useState<ReactJSXElement>()
  const [isBackDisabled, setIsBackDisabled] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isGroup, setIsGroup] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [genre, setGenre] = useState<{ label: string, value: string } | undefined>()
  const [secondaryGenre, setSecondaryGenre] = useState<{ label: string, value: string }>()
  const [handle, setHandle] = useState('')

  const flowProps = {
    onChange,
    onNext,
  };

  useEffect(() => {
    if (!existingProfile || !session) return
    const p = session.profile
    const u = session.user
    setName(p.name || '')
    setIsGroup(false)
    setGroupName(p.name || '')
    setHandle(p.handle || '')
    if (u?.email) setEmail(u.email)
  }, [existingProfile, session])

  useEffect(() => {
    switch (flowState) {
      case FlowState.login:
        setIsBackDisabled(true)
        setSlide(<Login {...flowProps} setSession={setSession} />)
        break
      case FlowState.personal:
        setIsBackDisabled(true);
        setSlide(<Personal {...flowProps} name={name} email={email} showTerms={existingProfile} />)
        break;
      case FlowState.soloOrGroup:
        setIsBackDisabled(false)
        setSlide(<SoloOrGroup {...flowProps} setIsGroup={setIsGroup} />)
        break;
      case FlowState.bandName:
        setIsBackDisabled(false)
        setSlide(<BandName {...flowProps} groupName={groupName} isGroup={isGroup} />)
        break
      case FlowState.genre:
        setIsBackDisabled(false)
        setSlide(<Genre onNext={onNext} onChange={onOptionChange} genre={genre} secondaryGenre={secondaryGenre} />)
        break
      case FlowState.handle:
        setIsBackDisabled(false)
        setSlide(<SelectHandle {...flowProps} isGroup={isGroup} handle={handle} />)
        break
      case FlowState.submit:
        setSlide(<Submit />)
        setIsBackDisabled(true)
        doSubmit()
          .then((success) => {
            if (!success) return onBack()
            window.location.href = '/profile'
          }).catch((e) => console.error("This shouldn't happen", e));
        break
      default: console.warn('Invalid flow state', flowState)
    }
    // Do NOT add flowProps to dependency array
  }, [flowState, setSlide, name, email, setIsGroup, isGroup, groupName, handle])

  function onChange(key: string, v: string) {
    console.log('onChange', [key, v])
    switch(key) {
      case 'name':
        setName(v)
        if (!groupName || name === groupName) {
          setGroupName(v)
        }
        break
      case 'email': return setEmail(v)
      case 'groupName': return setGroupName(v)
      case 'handle': return setHandle(v)
      default: console.warn('Mismatched key', key)
    }
  }

  function onOptionChange(key: string, opt: {label: string, value: string}) {
    switch (key) {
      case 'genre': return setGenre(opt)
      case 'genreSecondary': return setSecondaryGenre(opt)
      default: console.warn('onOptionChange, unmatched key', key)
    }
  }

  function onBack() {
    let newFlow = flowState - 1
    if (existingProfile) {
      // Handle flows that should be skipped. Start at end and work backwards.
      if (newFlow === FlowState.handle) {
        newFlow--;
      }
      if (newFlow === FlowState.bandName) {
        newFlow--;
      }
      if (newFlow === FlowState.soloOrGroup) {
        newFlow--;
      }
    }
    setFlowState(newFlow)
  }

  function onNext() {
    let newFlow = flowState + 1
    if (existingProfile) {
      // Handle flows that should be skipped. Start at first slide and work forwards.
      if (newFlow === FlowState.soloOrGroup) {
        newFlow++;
      }
      if (newFlow === FlowState.bandName) {
        newFlow++;
      }
      if (newFlow === FlowState.handle) {
        newFlow++;
      }
    }
    setFlowState(newFlow)
  }

  async function doSubmit() {
    try {
      if (session && email) {
        await updateUser({uuid: session?.user.uuid, email, name})
      }

      const eventData = {
        events: [Event.BATTLE_OF_THE_BANDS_2024],
        eventRegistrations: {
          [Event.BATTLE_OF_THE_BANDS_2024]: {
            type: BattleOfTheBandsEventType.CREATOR,
            genre: genre?.value,
            subgenre: secondaryGenre?.value,
          }
        }
      }

      if (existingProfile) {
        const p = {
          ...eventData
        }
        await updateProfile(p)
        return true
      }

      let personalData: ProfileTypes = {
        name: name,
        description: `${name}'s personal profile.`,
      }

      const groupInfo = {
        handle,
        ...eventData
      }

      if (name === groupName) {
        personalData = {...personalData, ...groupInfo}
      }
      const personalProfile = await createProfile(personalData)
      console.log('personalProfile', personalProfile)

      if (name === groupName) {
        return true
      }

      const groupProfile = await createProfile({
        name: groupName,
        description: `${groupName}'s band profile.`,
        ...groupInfo,
      });
      console.log('groupProfile', groupProfile);

      return true;
    } catch (e) {
      console.error('Failed to submit', e)
      alert('Something went wrong. Please try again.')
      return false;
    }
  }

  return (
    <>
      <NavButtons onBack={onBack} disabled={isBackDisabled} />
      <div className='dj3n-botb'>
        {slide}
      </div>
    </>
  )
}
